:root {
    --nav-width: 240px;
    --header-height: 60px;
    --footer-height: 0px;
    --bs-primary-rgb: rgba(52, 96, 157, 1);
}


.fade:not(.show) {
    opacity: 1;
}


.p-3px {
    padding: 3px !important;
}

.w-80px {
    min-width: unset;
    width: 80px !important;
}

.w-90px {
    min-width: unset;
    width: 90px !important;
}

.w-128px {
    min-width: unset;
    width: 128px !important;
}

.w-148px {
    min-width: unset;
    width: 148px !important;
}


.text-primary {
    color: var(-bs-primary-rgb) !important;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Pretendard;
}

.flex-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mb-5px {
    margin-bottom: 5px;
}

.mb-13px {
    margin-bottom: 13px;
}

.mb-20px {
    margin-bottom: 20px !important;
}

.mb-40px {
    margin-bottom: 40px !important;
}

.mb-55px {
    margin-bottom: 55px !important;
}

.flex-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.error-message {
    color: red;
    position: absolute;
    left: 178px;
    top: 40px;
    font-size: 12px;
}

.success-message {
    color: green;
    position: absolute;
    left: 177px;
    top: 37px;
    font-size: 12px;
}

.error-messageLogin {
    color: red;
    margin-top: 5px;
    font-size: 14px;
    line-height: 16.71px;
}

.h-100vh {
    height: 100vh !important;
}

.my-10px {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.ms-10px {
    margin-left: 10px;
}

.me-10px {
    margin-right: 10px !important;
}

.me-12px {
    margin-right: 12px;
}

.mt-6px {
    margin-top: 6px
}

.mt-10px {
    margin-top: 10px !important;
}

.h-48px {
    height: 48px !important;
}

.public_header_wrapper {
    width: 100%;
    height: 60px;
    background-color: rgba(68, 78, 87, 1);
}

.public_header_outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1350px;
    height: 60px;
    margin: 0 auto;
}

.logo_holder {
    width: 261px;
    height: 28px;
    background: url(../public/images/logo.png) no-repeat center;
    background-size: auto;
    padding-left: 20px;
}

.header_menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 875px;
    height: 60px;
}

.header_menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.header_menu ul li a,
.header_menu ul li a:hover,
.header_menu ul li a:focus,
.header_menu ul li a:active,
.header_menu ul li a:visited {
    color: rgba(255, 255, 255, 1);
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.09px;
    text-align: center;
    text-decoration: none;
}

.header_menu ul li:not(:first-child) {
    margin-left: 60px;
}

.content_main {
    width: 100%;
    height: fit-content;
    min-height: 979px;
    background: url(../public/images/background1.png) no-repeat center;
    background-size: cover;
}

.public_footer_wrapper {
    width: 100%;
    height: 226.27px;
    background-color: rgba(68, 78, 87, 1);
}

.public_footertop_wrapper {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid rgba(65, 65, 77, 1);
}

.public_footertop_outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 0 solid #fff;
    width: 100%;
    max-width: 1360px;
    height: 60px;
    margin: 0 auto;
}

.public_footer_content {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1360px;
    height: 166.27px;
    margin: 0 auto;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

.footer_right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 268px;
    height: 60px;
    border-width: 0 1px 0 1px;
    border-style: solid;
    border-color: rgba(65, 65, 77, 1);
}

.footer_right>a,
.footer_right>a:hover,
.footer_right>a:focus,
.footer_right>a:active,
.footer_right>a:visited {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 222px;
    height: 32px;
    color: rgba(255, 255, 255, 1);
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.09px;
    text-align: left;
    text-decoration: none;
    margin-left: 17px;
}

.footer_left ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer_left ul li a,
.footer_left ul li a:hover,
.footer_left ul li a:focus,
.footer_left ul li a:active,
.footer_left ul li a:visited {
    color: #fff;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.09px;
    text-align: left;
    text-decoration: none;
}

.footer_left ul li {
    position: relative;
    margin-right: 30px;
}

.footer_left ul li:first-child a,
.footer_left ul li:first-child a:hover,
.footer_left ul li:first-child a:focus,
.footer_left ul li:first-child a:active,
.footer_left ul li:first-child a:visited {
    color: rgba(110, 233, 153, 1);
}

.footer_left ul li:not(:first-child) {
    margin-left: 30px;
}

.footer_left ul li:not(:first-child)::before {
    content: "";
    content: "";
    width: 1px;
    height: 14px;
    border-left: 1px solid #fff;
    position: absolute;
    left: -30px;
    top: 5px;
}

button.btn.button-home,
button.btn.button-home:hover,
button.btn.button-home:focus,
button.btn.button-home:active {
    width: 220px;
    height: 70px;
    background: url(../public/images/home_btn_outline.png) no-repeat center;
    background-size: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    line-height: 34px;
    text-align: left;
    color: #fff;
    border: none;
}

.content_wrapper {
    width: 100%;
    max-width: 1360px;
    min-height: 979px;
    margin: 0 auto;
    padding: 161px 0 60px 0;
}

.content_wrapper h1 {
    color: #fff;
    font-family: Pretendard;
    font-size: 52px;
    font-weight: 600;
    line-height: 80px;
    text-align: left;
    margin-bottom: 32px;
    margin-top: 12px;
}

.content_wrapper h3 {
    color: #fff;
    font-family: Pretendard;
    font-size: 28px;
    font-weight: 400;
    line-height: 50px;
    text-align: left;
    margin: 0;
}

.home_boxes_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    margin-top: 102px;
    flex-wrap: wrap;
    gap: 12px;
}

.home_box {
    width: 300px;
    height: 300px;
    border-radius: 10px;
    background: rgba(238, 238, 238, 1);
    padding: 30px 15px;
}

.home_box img {
    display: block;
    width: 100px;
    height: 100px;
    margin: 0 auto;
}

.home_box h4 {
    text-align: center;
    font-family: Pretendard;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.64px;
    text-align: center;
    color: rgba(76, 76, 76, 1);
    margin: 33px 0 18px 0;
}

.home_box div {
    text-align: center;
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.48px;
    text-align: center;
    color: rgba(76, 76, 76, 1);
}

.login_content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 843px;
    background-color: #F6F7FA;
}

.login_box {
    width: 461.55px;
    height: 637px;
    border-radius: 5px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    background-color: #fff;
    padding: 34px 40px;
    border-color: rgba(235, 235, 235, 1);
}

.login_box img {
    display: block;
    margin: 0 auto;
}

.login_text {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: center;
    color: rgba(76, 76, 76, 1);
    margin: 34px 0;
}

.frm_login_row {
    margin-bottom: 17px;
}

.frm_login_row .form-label {
    color: rgba(76, 76, 76, 1);
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: center;
    margin-bottom: 10px;
}

.frm_login_row input.form-control::placeholder {
    color: rgba(197, 197, 197, 1);
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: left;
}

.frm_login_row div.input-group {
    height: 48px;
}

.frm_login_row div.input-group .input-group-text {
    background-color: unset;
    border-color: rgba(177, 177, 177, 1);
}

.frm_login_row div.input-group input.form-control {
    border-left-width: 0;
    padding-left: 0;
    border-color: rgba(177, 177, 177, 1);
}

input.form-control,
input.form-control:focus,
select.form-select,
select.form-select:focus {
    outline: none;
    box-shadow: none;
}

.remember_outer {
    width: 100%;
    height: 18px;
}

.remember_outer .form-check-label {
    color: rgba(0, 0, 0, 1);
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
    text-align: center;
    margin-left: 10px;
}

.remember_outer.form-check {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    margin-top: 7px;
    justify-content: end;
}

input.form-check-input:focus {
    box-shadow: none;
}

.remember_outer .form-check-input {
    margin: 0;
    padding: 0;
    box-shadow: none;
}

ul.login_links {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 7px;
    margin-bottom: 14px;
}

ul.login_links li:not(:first-child) {
    margin-left: 25px;
}

ul.login_links li a,
ul.login_links li a:hover,
ul.login_links li a:focus,
ul.login_links li a:active,
ul.login_links li a:visited {
    width: fit-content;
    height: 28px;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.32px;
    text-align: center;
    color: rgba(136, 136, 136, 1);
    text-decoration: none;
}

button.btn.btn_kakao,
button.btn.btn_kakao:hover,
button.btn.btn_kakao:focus,
button.btn.btn_kakao:active {
    width: 100%;
    background-color: none;
    height: 48px;
    border-radius: 4px;
    box-shadow: none;
    margin-bottom: 7px;
    background: url(../public/images/btn_kakao.png) no-repeat center;
    background-size: auto;
    border: none;
}

button.btn.btn_naver,
button.btn.btn_naver:hover,
button.btn.btn_naver:focus,
button.btn.btn_naver:active {
    width: 100%;
    background-color: none;
    height: 48px;
    border-radius: 4px;
    box-shadow: none;
    background: url(../public/images/btn_naver.png) no-repeat center;
    background-size: auto;
    border: none;
}

.memberterms_content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 1455px;
    background-color: #F6F7FA;
}

.card.memberterms_box {
    width: 100%;
    max-width: 800px;
    min-height: 1170px;
    border-color: rgba(235, 235, 235, 1);
}

.card.memberterms_box .card-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 87px;
    background-color: #fff;
    border-color: rgba(235, 235, 235, 1);
    padding-left: 20px;
    padding-right: 20px;
}

.memberterms_box .card-header {
    height: 70px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-color: rgba(235, 235, 235, 1);
    padding: 0 23px;
}

.memberterms_box .card-header .card-title {
    margin: 0;
    color: rgba(76, 76, 76, 1);
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
}

.card.memberterms_box .card-body {
    padding-left: 22px;
    padding-right: 22px;
}

.stepper_wrapper.lg {
    max-width: 877px;
    margin: 0 auto 65px auto;
}

.stepper_wrapper.lg .stepper_line {
    left: 8%;
    width: 90%;
}

.stepper_wrapper {
    width: 100%;
    max-width: 677px;
    height: 99px;
    border: 0 solid red;
    margin: 32px auto 50px auto;
    position: relative;
}

.stepper_wrapper ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.stepper_wrapper ul li {
    width: 33.33%;
    min-height: 99.9px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    min-width: 116px;
}

.stepper_wrapper ul li:first-child::after {
    width: 50%;
    right: 0
}

.stepper_wrapper ul li::after {
    content: "";
    width: 100%;
    border-top: 1.5px solid rgba(81, 81, 81, 1);
    position: absolute;
    top: 30px;
}

.stepper_wrapper ul li:last-child::after {
    width: 50%;
    left: 0
}

.stepper_wrapper ul li .stepper_dot {
    width: 60px;
    height: 60px;
    border: 0 solid #000;
    position: absolute;
    z-index: 99;
    border-radius: 50%;
    background-color: #fff;
}

.stepper_wrapper ul li .stepper_text {
    color: rgba(163, 163, 163, 1);
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.48px;
    text-align: center;
    padding-top: 78px;
    word-wrap: break-word;
}

.stepper_wrapper.lg ul li .stepper_text {
    padding-top: 95px;
}

.stepper_wrapper ul li.active .stepper_text {
    color: rgba(76, 76, 76, 1);
}

.stepper_wrapper ul li .stepper_dot.dot_1,
.stepper_wrapper ul li.active .stepper_dot.dot_1 {
    background: url(../public/images/step1_active.png) no-repeat center;
    background-size: auto;
}

.stepper_wrapper ul li .stepper_dot.dot_2 {
    background: url(../public/images/step2.png) no-repeat center;
    background-size: auto;
}

.stepper_wrapper ul li.active .stepper_dot.dot_2 {
    background: url(../public/images/step2_active.png) no-repeat center;
    background-size: auto;
}

.stepper_wrapper ul li .stepper_dot.dot_3 {
    background: url(../public/images/step3.png) no-repeat center;
    background-size: auto;
}

.stepper_wrapper ul li.active .stepper_dot.dot_3 {
    background: url(../public/images/step3.png) no-repeat center;
    background-size: auto;
}

.stepper_line {
    display: none;
    left: 10px;
    top: 30px;
    width: calc(100% - 40px);
    height: 2px;
    position: absolute;
    z-index: 1;
    border-top: 1.5px solid rgba(81, 81, 81, 1);
}

.membership_text {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
    text-align: center;
    margin-bottom: 48px;
}

.terms_section_wrapper {}

.terms_section_wrapper h4 {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
    padding-left: 7px;
    margin-bottom: 13px;
}

.terms_section_wrapper h4 span {
    color: rgba(255, 45, 45, 1);
}

.terms_content_box {
    width: 100%;
    height: 169px;
    border: 1px solid rgba(228, 228, 228, 1);
    border-radius: 5px;
    padding: 12px 15px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
    margin-bottom: 14px;
    overflow-y: auto;
}

.terms_agree_btm {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.terms_agree_btm .form-check-label {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
}

label.form-check-label.lbl_agreeAll {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
}

button.btn.btn-secondary,
button.btn.btn-secondary:hover,
button.btn.btn-secondary:focus,
button.btn.btn-secondary:active {
    width: 120px;
    border-radius: 3px;
    height: 38px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: center;
    color: #fff;
    background-color: rgba(121, 121, 121, 1);
    border-color: rgba(121, 121, 121, 1);
}

button.btn.btn-dark,
button.btn.btn-dark:hover,
button.btn.btn-dark:focus,
button.btn.btn-dark:active {
    width: 120px;
    border-radius: 3px;
    height: 38px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: center;
    color: #fff;
    background-color: #444;
    border-color: #444;
}

button.btn.btn-primary,
button.btn.btn-primary:hover,
button.btn.btn-primary:focus,
button.btn.btn-primary:active {
    min-width: 120px;
    border-radius: 3px;
    height: 38px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: center;
    color: #fff;
    background-color: rgba(52, 96, 157, 1);
    border-color: #34609d;
}

button.btn.btn-outline-secondary,
button.btn.btn-outline-secondary:hover,
button.btn.btn-outline-secondary:focus,
button.btn.btn-outline-secondary:active {
    min-width: 120px;
    border-radius: 3px;
    height: 38px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: center;
    color: rgba(76, 76, 76, 1);
    background-color: #fff;
    border-color: rgba(76, 76, 76, 1);
}

button.btn.btn-outline-danger,
button.btn.btn-outline-danger:hover,
button.btn.btn-outline-danger:focus,
button.btn.btn-outline-danger:active {
    min-width: 120px;
    border-radius: 3px;
    height: 38px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: center;
    color: #dc3545;
    background-color: #fff;
    border-color: #dc3545;
}

button.btn.btn_login,
button.btn.btn_login:hover,
button.btn.btn_login:focus,
button.btn.btn_login:active {
    width: 100%;
    background-color: rgba(52, 96, 157, 1);
    height: 48px !important;
    border-radius: 4px !important;
    box-shadow: none;
    color: #fff;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.09px;
    text-align: center;
    margin-bottom: 7px;
}

.userinfo_content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 1380px;
    background-color: #F6F7FA;
}

.card.userinfo_box {
    width: 100%;
    max-width: 800px;
    min-height: 1060px;
    border-color: rgba(235, 235, 235, 1);
}


.card.userinfo_box .card-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 85px;
    background-color: #fff;
    border-color: rgba(235, 235, 235, 1);
    padding-left: 20px;
    padding-right: 20px;
}

.userinfo_box .card-header {
    height: 70px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-color: rgba(235, 235, 235, 1);
    padding: 0 23px;
}

.userinfo_box .card-header .card-title {
    margin: 0;
    color: rgba(76, 76, 76, 1);
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
}

.card.userinfo_box .card-body {
    padding-left: 40px;
    padding-right: 40px;
}

.userinfo_text {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
    text-align: center;
    margin-bottom: 60px;
}

.userinfo_frm_outer .frm_row {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    position: relative;
}

.userinfo_frm_outer .frm_row:last-child {
    margin-bottom: 0;
}

.card.userinfo_auth_box {
    width: 100%;
    max-width: 800px;
    min-height: 940px;
    border-color: rgba(235, 235, 235, 1);
}

.card.userinfo_auth_box .card-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 85px;
    background-color: #fff;
    border-color: rgba(235, 235, 235, 1);
    padding-left: 20px;
    padding-right: 20px;
}

.userinfo_auth_box .card-header {
    height: 70px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-color: rgba(235, 235, 235, 1);
    padding: 0 23px;
}

.userinfo_auth_box .card-header .card-title {
    margin: 0;
    color: rgba(76, 76, 76, 1);
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
}

.card.userinfo_auth_box .card-body {
    padding-left: 40px;
    padding-right: 40px;
}

.userinfo_frm_outer .frm_row:last-child {
    margin-bottom: 0;
}

.frm_row .frm_label {
    width: 177px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
}

.frm_row .frm_field {
    display: flex;
    align-items: center;
    width: calc(100% - 177px);
    position: relative;
}

.frm_row .frm_field button.btn {
    min-width: 120px;
}

.frm_row input.form-control,
.frm_row select.form-select {
    height: 38px;
    border-color: rgba(167, 167, 167, 1);
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
    border-radius: unset;
}

.frm_row input.form-control::placeholder {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: left;
    color: rgba(182, 182, 182, 1);
}

.frm_row .frm_field>*:not(:first-child) {
    margin-left: 12px;
}

.frm_field.input_phone input.form-control {
    max-width: 126px;
}

.frm_field.input_date input.form-control {
    max-width: 222px;
}

.frm_field.input_email input.form-control {
    max-width: 165px;
}

.frm_field.input_email select.form-select {
    max-width: 172px;
    margin-left: 9px;
}

.frm_row .frm_field>.txt_range {
    margin-left: 10px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
}

.frm_row .frm_field>.txt_range+input.form-control {
    margin-left: 10px;
}

div.modal_alert1 .modal-content {
    /* width: 462px; */
    min-height: 309px;
    border-radius: 5px;
    border-color: rgba(235, 235, 235, 1);
}

div.modal_alert1 {
    --bs-modal-width: 462px;
}

div.modal_alert1 .modal-content .modal-body {
    padding: 40px;
}

div.modal_alert1 img {
    display: block;
    margin: 0 auto;
}

div.modal_alert1 h5 {
    font-family: Pretendard;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.64px;
    text-align: center;
    color: rgba(76, 76, 76, 1);
    text-align: center;
    margin: 20px 10px;
}

div.modal_alert1 h5.singleLine {
    margin: 20px 10px 30px 10px;
}

div.modal_alert1 p {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: center;
    color: rgba(76, 76, 76, 1);
    margin-bottom: 30px;
    word-wrap: break-word;
}

div.modal_alert1 p span.text-primary {
    color: rgba(52, 96, 157, 1) !important;
}

.findid_content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 793px;
    background-color: #F6F7FA;
}

.card.findid_box {
    width: 100%;
    max-width: 797px;
    min-height: 420px;
    border-color: rgba(235, 235, 235, 1);
}

.card.findid_box .card-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 90px;
    background-color: #fff;
    border-color: rgba(235, 235, 235, 1);
    padding-left: 20px;
    padding-right: 20px;
}

.findid_box .card-header {
    height: 70px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-color: rgba(235, 235, 235, 1);
    padding: 0 23px;
}

.findid_box .card-header .card-title {
    margin: 0;
    color: rgba(76, 76, 76, 1);
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
}

.card.findid_box .card-body {
    padding-left: 40px;
    padding-right: 40px;
}

.findid_text {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.09px;
    text-align: center;
    color: rgba(76, 76, 76, 1);
    text-align: center;
    margin-bottom: 50px;
    margin-top: 30px;
}

.findid_frm_outer .frm_row {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    position: relative;
}

.findid_frm_outer .frm_row:last-child {
    margin-bottom: 0;
}

.findpwd_content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    min-height: 865px;
    background-color: #F6F7FA;
}

.card.findpwd_box {
    width: 100%;
    max-width: 797px;
    min-height: 670px;
    border-color: rgba(235, 235, 235, 1);
}

.card.findpwd_box .card-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 90px;
    background-color: #fff;
    border-color: rgba(235, 235, 235, 1);
    padding-left: 20px;
    padding-right: 20px;
}

.findpwd_box .card-header {
    height: 70px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-color: rgba(235, 235, 235, 1);
    padding: 0 23px;
}

.findpwd_box .card-header .card-title {
    margin: 0;
    color: rgba(76, 76, 76, 1);
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
}

.card.findpwd_box .card-body {
    padding-left: 40px;
    padding-right: 40px;
}

.findpwd_text {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.09px;
    text-align: center;
    color: rgba(76, 76, 76, 1);
    text-align: center;
    margin-bottom: 50px;
    margin-top: 30px;
}

.findpwd_frm_outer .frm_row {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    position: relative;
}

.findpwd_frm_outer .frm_row:last-child {
    margin-bottom: 0;
}

.main-sidebar {
    display: flex;
    height: 100vh;
}

.nav-container {
    width: var(--nav-width);
    min-width: var(--nav-width);
    background-color: rgba(52, 96, 157, 1);
    overflow: hidden;
    min-height: 100vh;
    z-index: 99;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    transition: transform 0.15s ease-in-out;
}

.nav-container .close-btn {
    display: none;
}

.nav-container::-webkit-scrollbar {
    display: none;
}

.nav-container .logo {
    margin: 38px 47px;
}

div.nav-list {
    padding: 0 10px;
}

.main-sidebar .nav-container .nav-list ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.main-sidebar .nav-container .nav-list>ul>li {
    margin-bottom: 9px;
}

.main-sidebar .nav-container .nav-list>ul>li>ul>li {
    margin-bottom: 0;
}

.main-sidebar .nav-container .nav-list ul li a,
.main-sidebar .nav-container .nav-list ul li a:hover,
.main-sidebar .nav-container .nav-list ul li a:focus,
.main-sidebar .nav-container .nav-list ul li a:active,
.main-sidebar .nav-container .nav-list ul li a:visited {
    color: #fff;
    display: flex;
    align-items: center;
    height: 40px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.71px;
    text-align: left;
    text-decoration: none;
    padding: 0 12px;
    border-radius: 8px;
}

.main-sidebar .nav-container .nav-list ul li a.active,
.main-sidebar .nav-container .nav-list ul li a.active:hover,
.main-sidebar .nav-container .nav-list ul li a.active:focus,
.main-sidebar .nav-container .nav-list ul li a.active:active,
.main-sidebar .nav-container .nav-list ul li a.active:visited,
.main-sidebar .nav-container .nav-list ul li.active a,
.main-sidebar .nav-container .nav-list ul li.active a:hover,
.main-sidebar .nav-container .nav-list ul li.active a:focus,
.main-sidebar .nav-container .nav-list ul li.active a:active,
.main-sidebar .nav-container .nav-list ul li.active a:visited {
    background-color: rgba(0, 50, 120, 1);
}

.main-sidebar .nav-container .nav-list ul li a img {
    margin-right: 15px;
}

.main-sidebar .nav-container .nav-list ul li ul li a,
.main-sidebar .nav-container .nav-list ul li ul li a:hover,
.main-sidebar .nav-container .nav-list ul li ul li a:focus,
.main-sidebar .nav-container .nav-list ul li ul li a:active,
.main-sidebar .nav-container .nav-list ul li ul li a:visited {
    color: #fff;
    display: flex;
    align-items: center;
    height: 38px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
    text-align: left;
    padding-left: 52px;
    margin-top: 3px;
}

.main-container {
    height: auto;
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--header-height);
    background-color: #fff;
    padding: 0 35px;
}

.body-box {
    height: calc(100vh - var(--header-height));
    overflow-y: auto;
    flex: 1 1;
}

.body-container {
    background-color: #F6F7FA;
    padding: 20px 40px;
    min-height: calc(100% - var(--footer-height));
    /* width: calc(100vw - var(--nav-width)); */
    display: flex;
    flex-direction: column;
    margin-left: 240px;
    flex-grow: 1;
    min-width: 0;
}

.footer-container {
    height: 0;
    width: calc(100vw - 240px);
    margin-top: auto;
    background-color: #ffffff;
    padding: 0;
}

.header_title {
    display: flex;
    align-items: center;
    font-family: Pretendard;
    font-size: 24px;
    font-weight: 800;
    line-height: 28.64px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
}

.header_title img {
    margin-right: 8px;
}

.header_toolbar {
    display: flex;
    align-items: center;
}

.header_toolbar ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.header_toolbar ul li {
    display: flex;
    align-items: center;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
    color: rgba(76, 76, 76, 1);
    margin-left: 22px;
}

.header_toolbar ul li span {
    font-weight: 700;
    margin: 0 5px;
}

.header_toolbar ul li a,
.header_toolbar ul li a:hover,
.header_toolbar ul li a:focus,
.header_toolbar ul li a:active,
.header_toolbar ul li a:visited {
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    text-align: center;
    color: rgba(76, 76, 76, 1);
    text-decoration: none;
}

.wd-100 {
    width: 100%;
}

.dash_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1616px;
    gap: 10px;
}

div.card.dash_left {
    width: 100%;
    max-width: 1148px;
    height: 352px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.04);
    overflow: hidden;
    border-color: rgba(235, 235, 235, 1);
}

div.card.dash_left div.card-header,
div.card.dash_right div.card-header {
    display: flex;
    align-items: center;
    height: 70px;
    background-color: #fff;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
    padding: 0 25px;
    border-color: rgba(235, 235, 235, 1);
}

div.card.dash_left div.card-body,
div.card.dash_right div.card-body {
    padding: 40px 20px 0 25px;
}

div.card.dash_right div.card-footer {
    height: 90px;
    border-color: rgba(235, 235, 235, 1);
    background-color: #fff;
    display: flex;
    align-items: center;
}

div.card.dash_right {
    width: 100%;
    max-width: 444px;
    height: 352px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.04);
    border-color: rgba(235, 235, 235, 1);
    overflow: hidden;
}

ul.dash_boxes {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.dash_boxes li {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    border: 1px solid rgba(224, 228, 237, 1);
    padding-top: 20px;
    cursor: pointer;
}

ul.dash_boxes li:not(:first-child) {
    margin-left: 25px;
}

ul.dash_boxes li img {
    display: block;
    margin: 0 auto;
}

ul.dash_boxes li h6 {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    margin: 16px 0;
}

ul.dash_boxes li h3 {
    font-family: Pretendard;
    font-size: 30px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: rgba(255, 138, 75, 1);
}

ul.dash_boxes li h3 span {
    color: rgba(0, 0, 0, 1);
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
}

ul.dash_ul_text {
    margin: 0;
    /* padding: 0; */
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: rgba(117, 117, 159, 1);
}

.row_frm_req {
    display: flex;
    align-items: center;
}

.row_frm_req input.form-control,
.row_frm_req input.form-control:focus {
    border-radius: 10px;
    height: 50px;
    margin-right: 10px;
    border-color: rgba(221, 221, 221, 1);
}

.row_frm_req button.btn.btn-primary,
.row_frm_req button.btn.btn-primary:hover,
.row_frm_req button.btn.btn-primary:focus,
.row_frm_req button.btn.btn-primary:active {
    width: 130px;
    height: 50px;
    border-radius: 10px;
    color: #fff;
    background-color: rgba(0, 106, 255, 1);
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
}

label.lbl_request {
    display: flex;
    align-items: center;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(76, 76, 76, 1);
}

div.dash_btm {
    margin: 10px 0;
}

div.card.dash_btm {
    width: 100%;
    max-width: 1616px;
    height: fit-content;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.04);
    overflow: hidden;
    border-color: rgba(235, 235, 235, 1);
}

div.card.dash_btm div.card-header {
    display: flex;
    align-items: center;
    height: 70px;
    background-color: #fff;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
    padding: 0 25px;
    border-color: rgba(235, 235, 235, 1);
}

div.card.dash_btm div.card-body {
    padding: 24px 20px 0 24px;
}

table.table.my_table thead tr {
    /* border-width: 0 0 1px 0; */
    height: 40px;
    border-bottom-color: rgba(52, 96, 157, 1);
}

table.table.my_table thead tr th {
    background-color: rgba(231, 239, 251, 1);
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.09px;
    text-align: center;
    color: rgba(76, 76, 76, 1);
    border: none;
    vertical-align: middle;
    padding: 10.5px 0;
}

table.table.my_table thead tr th input.form-check-input,
table.table.my_table tbody tr td input.form-check-input {
    margin: 0;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    cursor: pointer;
}

table.table.my_table tbody tr {
    height: 34px;
}

table.table.my_table tbody tr td {
    color: rgba(76, 76, 76, 1);
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: left;
    border-color: rgba(200, 200, 200, 1);
    vertical-align: middle;
    padding: 2.5px 13px;
    text-wrap: nowrap;
}

table.table.my_table tbody tr td:first-child {
    border-left-width: 0;
}

table.table.my_table tbody tr td:last-child {
    border-right-width: 0;
}

table.table.my_table tbody tr td input.form-control,
table.table.my_table tbody tr td select.form-select {
    border-radius: unset;
    height: 34px;
}

table.card_table tbody tr td textarea.form-control,
table.card_table tbody tr td textarea.form-control:focus,
table.table.my_table tbody tr td textarea.form-control,
table.table.my_table tbody tr td textarea.form-control:focus {
    border-radius: unset;
    resize: none;
    box-shadow: none;
    border-color: rgba(220, 220, 220, 1);
}

table.table.tbody_noborder tbody tr td {
    border: none;
}

.pagination_container ul li {
    display: inline-block;
    margin-right: 5px;
}

.pagination_container ul li.active {
    font-weight: bold;
    color: rgba(52, 96, 157, 1)
}

.pagination_container ul li a {
    color: rgba(76, 76, 76, 1);
    text-decoration: none;
}

.pagination_container ul li a:hover {
    color: rgb(100, 100, 100);
    /* hover 상태 */
}


.pagination_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.pagination_container ul {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    cursor: pointer;
}


.pagination_container ul li a.active {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.09px;
    text-align: center;
    color: rgb(250, 8, 8) !important;
    /* 강조 */
    text-decoration: none;
}

.pagination_container ul li a,
.pagination_container ul li a:hover,
.pagination_container ul li a:focus,
.pagination_container ul li a:visited {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.09px;
    text-align: center;
    color: rgba(76, 76, 76, 1);
    text-decoration: none;
}


.pagination_container ul li.pg_nav {
    margin: 0 0 0 6px;
}

.pagination_container ul li {
    margin: 0 12px;
    color: #d3d3d3;
}

.pagination_container ul li.disabled {
    opacity: 0.3;
}

input.form-control.toolbar_date {
    width: 168px;
    height: 36px;
    float: right;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
}

input.form-control.input_round::-webkit-inner-spin-button,
input.form-control.input_round::-webkit-calendar-picker-indicator {
    background: none;
}

div.card.card_id_verify {
    margin: 12.5% auto 0 auto;
    width: 461px;
    height: 445px;
}

div.card.card_id_verify .card-body {
    padding: 58px 40px;
}

div.card.card_id_verify img.box_img {
    display: block;
    margin: 0 auto;
}

div.card.card_id_verify h3 {
    font-family: Pretendard;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.64px;
    text-align: center;
    color: rgba(76, 76, 76, 1);
    margin: 20px 0 24px 0;
}

div.card.card_id_verify p {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: center;
    color: rgba(76, 76, 76, 1);
    margin-bottom: 42px;
}

button.btn.btn_verify,
button.btn.btn_verify:hover,
button.btn.btn_verify:focus,
button.btn.btn_verify:active {
    width: 100%;
    height: 48px;
    margin-top: 20px;
}

div.card.card_main {
    width: 100%;
    max-width: 1616px;
    border-radius: 12px;
    overflow: hidden;
    border-color: rgba(224, 228, 237, 1);
}

div.card.card_main div.card-header {
    width: 100%;
    max-width: 1616px;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    background-color: #fff;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: rgba(76, 76, 76, 1);
    border-color: rgba(224, 228, 237, 1);
}

div.card.card_main div.card-header span {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
}

h3.table_heading {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
    margin-bottom: 10px;
    padding-left: 10px;
}

div.card.card_main div.card-body {
    padding: 50px 45px 15px 45px;
}

table.table.card_table {
    margin-bottom: 12px;
}

div.table_scroll table.table.card_table {
    margin-bottom: 0;
}

table.table.card_table thead tr,
table.table.card_table tbody tr {
    height: 52px;
}

table.table.card_table tbody tr th {
    background-color: rgba(249, 249, 249, 1);
    vertical-align: middle;
    border-color: rgba(220, 220, 220, 1);
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
    padding: 0 20px;
    text-wrap: nowrap;
}

table.table.card_table tbody tr th:first-child {
    border-left-color: rgba(249, 249, 249, 1);
}

table.table.card_table tbody tr td {
    padding: 7px 10px;
    vertical-align: middle;
}

table.table.card_table tbody tr td:last-child {
    border-right-color: #fff;
}

table.table.card_table tbody tr td input.form-control {
    width: 100%;
    border-color: rgba(220, 220, 220, 1);
    border-radius: unset;
}

div.card.card_main div.card-footer {
    display: flex;
    align-items: center;
    height: 86px;
    border-color: rgba(224, 228, 237, 1);
    background-color: #fff;
    padding: 0 45px;
}

div.card_footer_btngrp_end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

div.card_footer_btngrp_end button.btn+button.btn {
    margin-left: 12px;
}

.input_phone_tbl,
.input_email_tbl {
    display: flex;
    align-items: center;
}

.input_phone_tbl input.form-control,
.input_phone_tbl select.form-select {
    width: 129px;
    border-radius: unset;
    border-color: rgba(220, 220, 220, 1);
}

.input_phone_tbl>*:not(:first-child),
.input_email_tbl>*:not(:first-child) {
    margin-left: 12px;
}

.input_email_tbl input.form-control,
.input_email_tbl select.form-select {
    width: 222px;
    border-radius: unset;
    border-color: rgba(220, 220, 220, 1);
}

.tbl_files_outer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.tbl_files_outer ul.list_files {
    display: flex;
    flex-direction: column;
    width: calc(100% - 130px);
    height: fit-content;
    min-height: 38px;
    list-style: none;
    margin: 0;
    padding: 0;
    border: 1px solid rgba(220, 220, 220, 1);
    padding: 10px 10px;
}

.tbl_files_outer ul.list_files li:not(:last-child) {
    /* margin-bottom: 5px; */
}

.tbl_files_outer ul.list_files li a,
.tbl_files_outer ul.list_files li a:hover,
.tbl_files_outer ul.list_files li a:focus,
.tbl_files_outer ul.list_files li a:active {
    text-decoration: none;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
}

.tbl_files_outer ul.list_files.blue li a,
.tbl_files_outer ul.list_files.blue li a:hover,
.tbl_files_outer ul.list_files.blue li a:focus,
.tbl_files_outer ul.list_files.blue li a:active {
    color: rgba(72, 97, 255, 1)
}

.tbl_files_outer ul.list_files li img {
    margin-left: 5px;
}

div.card.card_data {
    width: 100%;
    max-width: 1616px;
    border-radius: 12px;
    overflow: hidden;
    border-color: rgba(224, 228, 237, 1);
}

div.card.card_data div.card-header {
    width: 100%;
    max-width: 1616px;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    background-color: #fff;
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: rgba(76, 76, 76, 1);
    border-color: rgba(224, 228, 237, 1);
}



div.card.card_data div.card-body {
    padding: 30px 24px 10px 24px;
}

h6.table_stats {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
    margin-bottom: 25px;
}

h6.table_stats span {
    font-family: Pretendard;
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    text-align: left;
    color: rgba(57, 61, 255, 1);
}

button.btn.btn_tbl,
button.btn.btn_tbl:hover,
button.btn.btn_tbl:focus,
button.btn.btn_tbl:active {
    width: 120px;
    height: 29px;
    border-radius: 5px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

table.table tbody tr td button.btn.btn_tbl {
    margin: 0 auto;
}

button.btn.btn-secondary.btn_tbl,
button.btn.btn-secondary.btn_tbl:hover,
button.btn.btn-secondary.btn_tbl:focus,
button.btn.btn-secondary.btn_tbl:active {
    width: 120px;
    height: 29px;
    border-radius: 5px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    color: #fff;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(131, 131, 131, 1);
    border-color: rgba(131, 131, 131, 1);
}

table.table tbody tr td .form-check-label {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    text-wrap: nowrap;
}

div.modal_alert2 .modal-content {
    /* width: 800px; */
    min-height: 541px;
    border-radius: 5px;
    border-color: rgba(235, 235, 235, 1);
}

div.modal_alert2 {
    --bs-modal-width: 800px;
}

div.modal_alert2 .modal-content .modal-body {
    padding: 24px 25px;
}

div.modal_alert2 .modal-header {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
    height: 70px;
}

div.modal_alert2 .modal-footer {
    height: 70px;
}


div.modal_alert3 .modal-content {
    min-height: 94px;
    border-radius: 5px;
    border-color: rgba(235, 235, 235, 1);
}

div.modal_alert3 {
    --bs-modal-width: 800px;
}

div.modal_alert3 .modal-content .modal-body {
    padding: 26px 25px;
}

.input_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input_row input.form-control {
    border-radius: unset;
    border-color: rgba(167, 167, 167, 1);
}

.input_row input.form-control::placeholder {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: left;
    color: rgba(167, 167, 167, 1);
}

.stamp_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.stamp_box {
    width: 50%;
    max-width: 370px;
    height: 303px;
    border: 1px solid rgba(167, 167, 167, 1);
    padding: 14px;
}

.stamp_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stamp_top h5 {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: left;
    color: rgba(68, 68, 68, 1);
}

.stamp_option {
    width: 100%;
    max-width: 340px;
    height: 220px;
    border: 1px solid rgba(167, 167, 167, 1);
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 15px;
}

.stamp_option label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 70px;
}

.stamp_option label input[type=radio] {
    margin-top: 25px;
}

button.btn.btn_modal_footer,
button.btn.btn_modal_footer:hover,
button.btn.btn_modal_footer:focus,
button.btn.btn_modal_footer:active {
    min-width: 90px;
}

.pdf_container {
    width: 100%;
    max-width: 1538px;
    height: 736px;
    background-color: rgba(217, 217, 217, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
    margin-bottom: 38px;
}

.pdf_placeholder_text {
    color: rgba(0, 0, 0, 1);
    font-family: Pretendard;
    font-size: 50px;
    font-weight: 700;
    line-height: 59.67px;
    text-align: left;
}

.grey_box_container {
    width: 100%;
    display: flex;
    align-items: center;
}

.grey_box_container .grey_box {
    width: calc(50% - 12px);
    height: 694px;
    background-color: rgba(217, 217, 217, 1);
}

.grey_box_container .grey_box:not(:first-child) {
    margin-left: 24px;
}

h3.card_body_heading {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: rgba(76, 76, 76, 1);
    margin-bottom: 25px;
}

.header-mob {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    background-color: #fff;
    padding: 0 18px;
    border-bottom: 1px solid rgba(246, 246, 246, 1);
}

button.btn#dropdown-header {
    padding: 0;
}

button.btn#dropdown-header::after {
    display: none;
}

.sb-sidenav-toggled .main-sidebar .nav-container {
    transform: translateX(0);
}

.sb-sidenav-toggled .main-container:before {
    content: "";
    display: block;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 1037;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
}

.nav-link {
    cursor: pointer;
}

.table_scroll {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 12px;
}

div.table_scroll table.table {
    min-width: 1490px;
}

div.card_top_with_btns {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

div.card_top_with_btns h6.table_stats {
    margin-top: 5px;
    margin-bottom: 0;
}

div.card_table_btntop {
    display: flex;
    align-items: center;
}

.stampimg_container {
    width: 100%;
    min-height: 394px;
    border: 1px solid rgba(220, 220, 220, 1);
    padding: 16px 14px;
}

.stampimg_container .toolbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.imgs_outer {
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.stamimg_box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 737px;
    height: 318px;
    border: 1px solid rgba(236, 236, 236, 1);
    margin-top: 15px;
    gap: 8px;
    padding: 14px;
}

.stamimg_box .imgoutr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 245px;
    height: 280px;
}

.stamimg_box .imgoutr div {
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(76, 76, 76, 1);
    margin-top: 8px;
}

.stamimg_box .imgoutr img {
    width: 100%;
    max-width: 250px;
    height: 258px;
    border: 1px solid rgba(237, 237, 237, 1);
    padding: 12px 18px;
}

.plan_content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: calc(100vh - 287px); */
    min-height: 865px;
    background-color: #F6F7FA;
}

.card.plan_box {
    width: 100%;
    max-width: 797px;
    min-height: 736px;
    border: 2px solid #149CFC;
    /* border-color: rgba(235, 235, 235, 1); */
    margin: 0;
}

.card.plan_box .card-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 90px;
    background-color: #fff;
    border-color: rgba(235, 235, 235, 1);
    padding-left: 20px;
    padding-right: 20px;
}

.plan_box .card-header {
    height: 70px;
    display: flex;
    align-items: center;
    background-color: #fff;
    border-color: rgba(235, 235, 235, 1);
    padding: 0 23px;
}

.plan_box .card-header .card-title {
    margin: 0;
    color: rgba(76, 76, 76, 1);
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
}

.plan_box {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.09px;
    text-align: center;
    color: rgba(76, 76, 76, 1);
    text-align: center;
    margin-bottom: 50px;
    margin-top: 30px;
}

.card.plan_box .card-body {
    padding-left: 25px;
    padding-right: 25px;
}

.plan_text {
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.09px;
    text-align: center;
    color: rgba(76, 76, 76, 1);
    text-align: center;
    margin-bottom: 30px;
    margin-top: 30px;
}

button.btn.btn_play_subscription,
button.btn.btn_play_subscription:hover,
button.btn.btn_play_subscription:focus,
button.btn.btn_play_subscription:active {
    color: #fff;
    width: 100%;
    height: 70px;
    font-family: "Pretendard";
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: center;
    background-color: rgba(68, 78, 87, 1);
    border-radius: 10px;
}

.plans_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 15px 0;
}

div.plan_sm_box {
    width: 100%;
    max-width: 234px;
    min-height: 332px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    align-items: center;
    padding: 7px;
    border: 1px solid rgba(224, 228, 237, 1);
    border-radius: 10px;
}

div.plan_sm_box.bordered {
    border: 1px solid rgba(68, 78, 87, 1);
    border-radius: 10px;
}

div.plan_sm_top {
    width: 100%;
    max-width: 218px;
    min-height: 95px;
    border-radius: 10px;
    background-color: rgba(235, 240, 246, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

div.plan_sm_top h4 {
    font-family: "Pretendard";
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    text-align: center;
    margin-bottom: 5px;
}

div.plan_sm_top.box1 {
    color: rgba(29, 147, 255, 1);
}

div.plan_sm_top.box2 {
    color: rgba(46, 117, 182, 1);
}

div.plan_sm_top.box3 {
    color: rgba(0, 32, 96, 1);
}

div.plan_sm_top p {
    font-family: "Pretendard";
    font-size: 21px;
    font-weight: 700;
    line-height: 25.2px;
    margin: 0;
}

div.plan_sm_top p small {
    font-family: "Pretendard";
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
}

div.plan_sm_btm {
    width: 100%;
    max-width: 218px;
    min-height: 230px;
    border-radius: 10px;
    background-color: rgba(243, 243, 243, 1);
    margin-top: 7px;
    padding: 30px 14px;
}

div.plan_sm_btm ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

div.plan_sm_btm ul ul {
    list-style: none;
    margin: 10px 0 0 0;
    padding: 0 0 0 20px;
}

div.plan_sm_btm ul li {
    color: rgba(76, 76, 76, 1);
    text-align: left;
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: 400;
    line-height: 19.09px;
    text-align: left;
    margin-bottom: 10px;
}

div.plan_sm_btm ul>li::before {
    display: inline-block;
    width: 12.5px;
    height: 11.5px;
    color: transparent;
    font-size: 1px;
    content: "";
    background: url(../public/images/icon_list_tick.png) center no-repeat;
    background-size: cover;
    margin-right: 10px;
}

div.plan_sm_btm ul ul li::before {
    display: none;
}

div.plan_sm_btm ul ul li {
    color: rgba(76, 76, 76, 1);
    font-family: "Pretendard";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 5px;
}

p.plan_btm_text {
    color: rgba(153, 153, 153, 1);
    font-family: "Pretendard";
    font-size: 14px;
    font-weight: 400;
    line-height: 16.71px;
    text-align: left;
}

input.form-check-input:checked {
    background-color: #34609D;
    border-color: #34609D;
}

::-webkit-file-upload-button,
::file-selector-button {
    display: none;
}

input[type=file].form-control {
    padding-top: 9px;
    padding-bottom: 9px;
}

table.table.my_table tbody tr.no-data-message {
    height: 200px;
}

.pc_view {
    display: block;
}

.mobile_view {
    display: none !important;
}

button.btn.btn_menu_mobile {
    padding: 0 20px 0 5px;
}

div.offcanvas.offcanvas-start {
    width: 66.66%;
    background: rgba(238, 238, 238, 1);
}

div.offcanvas .offcanvas-body {
    padding: 40px 20px;
}

ul.offcanvas_menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.offcanvas_menu li {
    margin-bottom: 18px;
}

ul.offcanvas_menu li a,
ul.offcanvas_menu li a:hover,
ul.offcanvas_menu li a:focus,
ul.offcanvas_menu li a:active,
ul.offcanvas_menu li a:visited {
    color: rgba(68, 78, 87, 1);
    font-family: "Pretendard";
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-decoration: none;
}

.privacy_heading {
    font-size: 36px;
    color: #333;
    margin: 40px 0 30px 0;
}

.container.policy_doc {
    padding-bottom: 40px;
}

.privacy_outer {
    margin: 0;
}

.privacy_outer h6 {
    font-size: 18px;
    margin: 15px 0;
}

.privacy_outer ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.privacy_outer ul li:not(:first-child) {
    margin: 15px 0 0 0;
}

.privacy_outer img {
    display: block;
    margin: 15px auto;
}

@media (max-width: 576px) {
    div.card.card_id_verify .card-body {
        padding: 58px 20px;
    }

    div.card_top_with_btns {
        align-items: flex-start;
        flex-direction: column;
    }

    div.card_table_btntop {
        width: 100%;
        justify-content: flex-end;
    }

    div.modal_alert1 .modal-content .modal-body {
        padding: 40px 20px;
    }

    div.modal_alert1 p {
        font-size: 14px;
        line-height: 16.71px;
    }

    .stepper_wrapper ul li .stepper_text {
        font-size: 14px;
        line-height: 16.71px;
        min-height: 128px;
    }

    div.card.card_main div.card-header,
    div.card.card_main div.card-header span {
        font-size: 14px;
        line-height: 16.71px;
    }
}

@media (max-width: 768px) {
    .privacy_outer h6 {
        font-size: 16px;
    }

    .privacy_heading {
        font-size: 20px;
        margin-top: 30px 0 20px 0;
    }

    .privacy_outer {
        font-size: 14px;
    }

    .privacy_outer img {
        width: 100%;
    }

    .stamp_container {
        flex-direction: column;
    }

    .stamp_box {
        width: 100%;
        max-width: unset;
    }

    .stamp_option {
        width: 100%;
        max-width: unset;
    }

    .stamp_container .stamp_box:first-child {
        margin-bottom: 10px;
    }
}

@media (max-width: 992px) {
    h6.table_stats {
        margin-bottom: 8px;
    }

    .plans_container {
        gap: 5px;
        align-items: flex-start;
    }

    .content_wrapper {
        padding: 35px 0 60px 0;
    }

    .content_wrapper,
    .public_header_outer,
    .public_footer_content {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .public_footer_wrapper {
        min-height: 195px;
    }

    .public_footertop_wrapper {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .public_footer_content {
        font-size: 12px;
        line-height: 20px;
        height: 195px;
        padding: 0 20px;
    }

    .content_wrapper {
        min-height: unset;
    }

    .home_box {
        width: 152px;
        height: 199px;
    }

    .home_box img {
        width: 50px;
        height: 50px;
    }

    .home_box h4 {
        font-size: 16px;
        line-height: 19.2px;
        margin: 0 0 15px 0;
    }

    .home_box div {
        font-size: 14px;
        line-height: 16.7px;
    }

    .content_main {
        min-height: 804px;
        height: fit-content;
    }

    .home_boxes_wrapper {
        flex-wrap: wrap;
        gap: 12px;
        justify-content: space-around;
        margin-top: 35px;
    }

    button.btn.button-home,
    button.btn.button-home:hover,
    button.btn.button-home:focus,
    button.btn.button-home:active {
        font-size: 16px;
        line-height: 34px;
        background: url(../public/images/home_btn_outline_sm.png) no-repeat center;
    }

    button.btn.button-home img {
        display: none;
    }

    .content_wrapper h3 {
        font-size: 18px;
        line-height: 50px;
    }

    .content_wrapper h1 {
        font-size: 28px;
        line-height: 43px;
        margin-top: 0;
        margin-bottom: 15px;
    }

    button.btn.button-home,
    button.btn.button-home:hover,
    button.btn.button-home:focus,
    button.btn.button-home:active {
        width: 190px;
    }

    .public_header_outer {
        justify-content: flex-start;
    }

    .header_menu {
        display: none;
    }

    .pc_view {
        display: none;
    }

    .mobile_view {
        display: block !important;
    }

    .logo_holder {
        width: 122px;
        height: 38px;
        background: url(../public/images/logo_mobile.png) no-repeat center;
        background-size: auto;
        padding-left: 20px;
    }

    .frm_row .frm_field button.btn,
    button.btn.btn-primary,
    button.btn.btn-primary:hover,
    button.btn.btn-primary:focus,
    button.btn.btn-primary:active,
    button.btn.btn-outline-danger,
    button.btn.btn-outline-danger:hover,
    button.btn.btn-outline-danger:focus,
    button.btn.btn-outline-danger:active,
    button.btn.btn-outline-secondary,
    button.btn.btn-outline-secondary:hover,
    button.btn.btn-outline-secondary:focus,
    button.btn.btn-outline-secondary:active {
        min-width: 80px;
    }

    div.card.card_main div.card-footer {
        padding-left: 15px;
        padding-right: 15px;
    }

    .main-container {
        width: 100%;
    }

    .input_phone_tbl input.form-control,
    .input_phone_tbl select.form-select {
        height: 38px;
    }

    input.input_phone_tbl input.form-control,
    select.input_phone_tbl select.form-select,
    h6.table_stats,
    h6.table_stats span {
        font-size: 14px;
        line-height: 16.71px;
    }

    h3.table_heading {
        font-size: 14px;
        line-height: 16.71px;
    }

    div.card.card_main div.card-header,
    div.card.card_data div.card-header {
        padding: 0 10px;
        flex-wrap: wrap;
    }

    div.card.card_main div.card-body,
    div.card.card_data div.card-body {
        padding: 20px 15px 20px 15px;
    }

    table.table.card_table tbody tr th {
        padding: 0 15px;
        font-size: 14px;
        line-height: 16.71px;
    }

    .header_toolbar {
        display: none;
    }

    .header-mob {
        display: flex;
    }

    .body-container {
        padding-left: 15px;
        padding-right: 15px;
    }

    div.card.card_id_verify {
        width: 100%;
        max-width: 459px;
    }

    .body-container,
    .header-container {
        margin-left: 0;
        transition: margin 0.15s ease-in-out;
    }

    .main-sidebar .nav-container {
        flex-basis: 240px;
        flex-shrink: 0;
        transition: transform 0.15s ease-in-out;
        z-index: 1038;
        transform: translateX(-240px);
        margin-top: 60px;
    }
}

@media(max-width: 768px) {
    .frm_login_row .form-label {
        font-size: 12px;
        font-weight: 400;
        line-height: 14.32px;
    }

    div.card.card_id_verify h3 {
        font-size: 20px;
        line-height: 23.87px;
    }

    div.card.card_id_verify p {
        font-size: 12px;
        line-height: 14.32px;
    }

    .header-container {
        height: 60px;
        padding: 0 20px;
    }

    .header_title img {
        width: 16px;
    }

    .header_title {
        font-family: Pretendard;
        font-size: 16px;
        font-weight: 800;
        line-height: 19.09px;
    }
}

.dropdown-menu.show {
    z-index: 9999;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

    .body-container,
    .header-container {
        margin-left: 0;
        transition: margin 0.15s ease-in-out;
    }

    .main-sidebar .nav-container {
        flex-basis: 240px;
        flex-shrink: 0;
        transition: transform 0.15s ease-in-out;
        z-index: 1038;
        transform: translateX(-240px);
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

    .main-sidebar .nav-container {
        transform: translateX(0);
    }

    .body-container,
    .header-container {
        margin-left: 240px;
        transition: margin 0.15s ease-in-out;
    }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background: white;
    padding: 20px;
    /* 패딩은 그대로 유지 */
    width: 800px;
    /* 너비를 늘려서 모달 크기를 증가 */
    height: auto;
    /* 높이는 자동으로 조정 */
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: auto;
    /* 내용이 넘칠 경우 스크롤 가능 */
    border-radius: 8px;
    /* 모서리를 둥글게 */
}

@media (max-width: 1360px) {
    .public_footertop_wrapper {
        padding-left: 15px;
        padding-right: 15px;
        /* display: none; */
    }

    .content_wrapper,
    .public_header_outer,
    .public_footer_content {
        padding-left: 15px;
        padding-right: 15px;
    }

    .header_menu {
        width: 65%;
    }
}

tbody tr.clickable:hover {
    cursor: pointer;
}

li.clickable:hover {
    cursor: pointer;
}

.delivery-text {
    font-size: 14px;
    color: #4c4c4c;
    font-weight: normal;
    vertical-align: middle;
    text-align: center;
}

.modal_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes progress {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
@font-face {
    font-family: 'Pretendard';
    font-weight: 100;
    font-style: normal;
    src: url('./Pretendard-Thin.eot');
    src: url('./Pretendard-Thin.eot?#iefix') format('embedded-opentype'),
        url('./Pretendard-Thin.woff2') format('woff2'),
        url('./Pretendard-Thin.woff') format('woff'),
        url('./Pretendard-Thin.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 200;
    font-style: normal;
    src: url('./Pretendard-ExtraLight.eot');
    src: url('./Pretendard-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('./Pretendard-ExtraLight.woff2') format('woff2'),
        url('./Pretendard-ExtraLight.woff') format('woff'),
        url('./Pretendard-ExtraLight.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 300;
    font-style: normal;
    src: url('./Pretendard-Light.eot');
    src: url('./Pretendard-Light.eot?#iefix') format('embedded-opentype'),
        url('./Pretendard-Light.woff2') format('woff2'),
        url('./Pretendard-Light.woff') format('woff'),
        url('./Pretendard-Light.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 400;
    font-style: normal;
    src: url('./Pretendard-Regular.eot');
    src: url('./Pretendard-Regular.eot?#iefix') format('embedded-opentype'),
        url('./Pretendard-Regular.woff2') format('woff2'),
        url('./Pretendard-Regular.woff') format('woff'),
        url('./Pretendard-Regular.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 500;
    font-style: normal;
    src: url('./Pretendard-Medium.eot');
    src: url('./Pretendard-Medium.eot?#iefix') format('embedded-opentype'),
        url('./Pretendard-Medium.woff2') format('woff2'),
        url('./Pretendard-Medium.woff') format('woff'),
        url('./Pretendard-Medium.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 600;
    font-style: normal;
    src: url('./Pretendard-SemiBold.eot');
    src: url('./Pretendard-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./Pretendard-SemiBold.woff2') format('woff2'),
        url('./Pretendard-SemiBold.woff') format('woff'),
        url('./Pretendard-SemiBold.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 700;
    font-style: normal;
    src: url('./Pretendard-Bold.eot');
    src: url('./Pretendard-Bold.eot?#iefix') format('embedded-opentype'),
        url('./Pretendard-Bold.woff2') format('woff2'),
        url('./Pretendard-Bold.woff') format('woff'),
        url('./Pretendard-Bold.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 800;
    font-style: normal;
    src: url('./Pretendard-ExtraBold.eot');
    src: url('./Pretendard-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('./Pretendard-ExtraBold.woff2') format('woff2'),
        url('./Pretendard-ExtraBold.woff') format('woff'),
        url('./Pretendard-ExtraBold.ttf') format("truetype");
    font-display: swap;
}

@font-face {
    font-family: 'Pretendard';
    font-weight: 900;
    font-style: normal;
    src: url('./Pretendard-Black.eot');
    src: url('./Pretendard-Black.eot?#iefix') format('embedded-opentype'),
        url('./Pretendard-Black.woff2') format('woff2'),
        url('./Pretendard-Black.woff') format('woff'),
        url('./Pretendard-Black.ttf') format("truetype");
    font-display: swap;
}